import Drift from 'drift-zoom';
const util = {
    initProductGallery: () => {
        let gallery = document.querySelectorAll('.cz-product-gallery');
      if (gallery.length) {

        for (let i = 0; i < gallery.length; i++) {
          
          let thumbnails = gallery[i].querySelectorAll('.cz-thumblist-item:not(.video-item)'),
              previews = gallery[i].querySelectorAll('.cz-preview-item'),
              videos = gallery[i].querySelectorAll('.cz-thumblist-item.video-item');


          for (let n = 0; n < thumbnails.length; n++) {
            thumbnails[n].addEventListener('click', changePreview);
          }

          // Changer preview function
          function changePreview(e) {
            e.preventDefault();
            for (let i = 0; i < thumbnails.length; i++) {
              previews[i].classList.remove('active');
              thumbnails[i].classList.remove('active');
            }
            this.classList.add('active');
            gallery[i].querySelector(this.getAttribute('href')).classList.add('active');
          }

          // Video thumbnail - open video in lightbox
          // for (let m = 0; m < gallery.length; m++) {
          //   lightGallery(gallery[m], {
          //     selector: 'this',
          //   });
          // }
        }
      }
    },
    imageZoom: () => {
        let images = document.querySelectorAll('.cz-image-zoom');
        for (let i = 0; i < images.length; i++) {
          new Drift(images[i], {
            paneContainer: images[i].parentElement.querySelector('.cz-image-zoom-pane')
          });
        }
    } 
  };
  
export default util;


  